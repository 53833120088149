.bg-nav {
  background-color: #323A47 !important;
}

.bg-nav-filter {
  background-color: #464D59;
}

​ .bg-body {
  background-color: #f5f6f7 !important;
}

​ .btn-primary {
  background-color: #4581f3 !important;
  border-color: #4581f3 !important;
}

.btn-warning {
  background-color: #edba00 !important;
  border-color: #edba00 !important;
}

.active-link {
  background-color: #007bff!important;
  border-radius: .25rem!important;
}

​ .underline {
  text-decoration: underline;
}

​ .highlight {
  background-color: lightyellow;
}

​ .bg-danger {
  background-color: #ea4335 !important;
}

​ .bg-warning {
  background-color: #edba00 !important;
}

​ .bg-primary {
  background-color: #4581f3 !important;
}

​ .bg-success {
  background-color: #01b257 !important;
}

​ .badge-success {
  background-color: #01b257 !important;
}

.bid-form-name {
  background-color: #8fb4f7;
}
